<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-info"
              >
                <feather-icon
                  size="24"
                  icon="BoxIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ dataItem.customers }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ dataItem.contract_templates }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col v-if="dataItem.pdate">
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-danger"
              >
                <feather-icon
                  size="24"
                  icon="CalendarIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                Proje Teslim Tarihi
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ moment(dataItem.pdate).format('DD.MM.YYYY') }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col>
          <b-row class="float-right">
            <b-dropdown
              id="print"
              variant="success"
              class="mr-2"
            >
              <template #button-content>
                <FeatherIcon icon="PrinterIcon" />
                Yazdır
              </template>
              <b-dropdown-item @click="download('tr')">
                Türkçe
              </b-dropdown-item>
              <b-dropdown-item @click="download('en')">
                İngilizce
              </b-dropdown-item>
            </b-dropdown>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="ListIcon" />
          Sözleşme İçeriği
        </template>
        <b-card
          v-if="dataItem.notes"
          title="Sözleşme Notu"
        >
          <div class="text-center">
            {{ dataItem.notes }}
          </div>
        </b-card>
        <b-card no-body>
          <b-card-header>
            <b-card-title>Sözleşme İçeriği</b-card-title>
          </b-card-header>
          <b-card-body>
            <div
              v-if="dataItem.contents"
              v-html="dataItem.contents"
            />
            <b-alert
              v-else
              show
              variant="info"
              class="mr-2 ml-2"
            >
              <div class="alert-body text-center">
                <div class="lead">
                  Sözleşme İçeriği Boş.
                </div>
                <div class="mt-1">
                  <b-button
                    variant="info"
                    size="sm"
                    :to="'/app/contracts/edit/' + $route.params.id"
                  >
                    Sözleşmeyi Güncelle
                  </b-button>
                </div>
              </div>
            </b-alert>
          </b-card-body>

        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          Dokümanlar
        </template>
        <b-card no-body>
          <b-card-header>
            <b-card-title>
              Dokümanlar
            </b-card-title>
            <b-button
              variant="primary"
              @click="openDocumentModal"
            >
              <feather-icon icon="PlusIcon" />
              Ekle
            </b-button>
          </b-card-header>
          <b-table
            v-if="documentList.length > 0 "
            responsive="sm"
            :fields="documentFields"
            :items="documentList"
            striped
          >
            <template #cell(title)="data">
              {{ data.item.title }}
            </template>
            <template #cell(control)="data">
              <div class="text-right">
                <b-dropdown
                  variant="primary"
                  text="Görüntüle"
                  left
                  split
                  size="sm"
                  @click="showDocument(data.item.fileUrl)"
                >
                  <b-dropdown-item @click="removeDocument(data.item.id)">
                    <feather-icon icon="XIcon" />
                    <span class="align-middle ml-50">Sil</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>

          </b-table>
          <b-alert
            v-else
            show
            variant="info"
            class="mr-2 ml-2"
          >
            <div class="alert-body text-center">
              <div class="lead">
                Doküman kaydı bulunamadı.
              </div>
              <div class="mt-1">
                <b-button
                  variant="info"
                  size="sm"
                  @click="openDocumentModal"
                >
                  Doküman Ekle
                </b-button>
              </div>
            </div>
          </b-alert>
          <b-card-footer>
            <b-pagination
              v-model="documentCurrentPage"
              :total-rows="documentCounts"
              :per-page="documentPerPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-card-footer>
        </b-card>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Bilgileri</span>
        </template>
        <customer-info />
        <customer-invoice />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="ClockIcon" />
          <span>Müşteri Geçmişi</span>
        </template>
        <customer-history :customer-id="dataItem.id_customers" />
      </b-tab>
    </b-tabs>
    <b-modal
      v-model="documentModal.status"
      title="Doküman Ekle"
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-form-group
        label="Başlık"
        label-for="title"
      >
        <b-form-input
          id="title"
          v-model="documentModal.title"
          placeholder="Başlık"
        />
      </b-form-group>
      <b-form-group
        label="Dosya Yükle"
        label-for="file-upload"
      >
        <b-form-file
          id="file-upload"
          v-model="documentModal.filename"
          placeholder="Bir dosya seçin..."
          drop-placeholder="Dosyayı buraya bırakın..."
          browse-text="Dosya Seçin"
        />
      </b-form-group>
      <template #modal-footer>
        <b-button
          v-if="!documentModal.loadSpinner"
          @click="documentModal.status = false"
        >
          Kapat
        </b-button>
        <b-button
          variant="success"
          :disabled="!documentModal.title || !documentModal.filename || documentModal.loadSpinner"
          @click="uploadDocument"
        >
          <b-spinner
            v-if="documentModal.loadSpinner"
            class="mr-1"
            variant="light"
            small
          />

          <feather-icon
            v-if="!documentModal.loadSpinner"
            icon="UploadIcon"
          />
          <template v-if="!documentModal.loadSpinner">
            Yükle
          </template>
          <template v-else>
            Yükleniyor
          </template>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BAlert,
  BButton,
  BCardFooter,
  BPagination,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BRow,
  BCol,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BCardText,
  BTab,
  BTabs, BFormInput, BFormGroup, BFormFile, BDropdownItem, BDropdown, BSpinner, BCardBody,
} from 'bootstrap-vue'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CustomerHistory from '@/views/Admin/Customers/View/CustomerHistory.vue'
import CustomerInvoice from '@/views/Admin/Customers/View/CustomerInvoice.vue'
import CustomerInfo from '@/views/Admin/Customers/View/CustomerInfo.vue'

export default {
  name: 'ViewContract',
  components: {
    BDropdown,
    BDropdownItem,
    BFormFile,
    BFormGroup,
    BFormInput,
    CustomerInfo,
    CustomerInvoice,
    CustomerHistory,
    BCardFooter,
    BButton,
    BTable,
    BPagination,
    BAlert,
    BCardHeader,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BCardText,
    BTab,
    BTabs,
    BSpinner,
    BCardBody,
  },
  data() {
    return {
      documentCurrentPage: 1,
      documentPerPage: this.$store.state.app.perPage,
      documentModal: {
        status: false,
        title: null,
        filename: null,
        loadSpinner: false,
      },
      options: {
        numeral: true,
        numeralDecimalMark: ',',
        delimiter: '.',
      },
      documentFields: [
        {
          key: 'title',
          label: 'Doküman',
          thClass: 'text-nowrap',
        },
        {
          key: 'username',
          label: 'Ekleyen',
        },
        {
          key: 'control',
          label: '',
          thClass: 'text-nowrap width-50',
        },
      ],
      dataQuery: {
        limit: this.$store.state.app.perPage,
        start: 0,
      },
      documentdataQuery: {
        select: [
          'contract_documents.id as id',
          'users.name as username',
          'contract_documents.title as title',
          'contract_documents.filename as filename',
        ],
        where: {
          'contract_documents.id_contracts': this.$route.params.id,
        },
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    moment() {
      return moment
    },
    dataItem() {
      return this.$store.getters['contracts/dataItem']
    },
    documentList() {
      return this.$store.getters['contractDocuments/dataList']
    },

    documentCounts() {
      return this.$store.getters['contractDocuments/dataCounts']
    },
    documentSaveStatus() {
      return this.$store.getters['contractDocuments/dataSaveStatus']
    },
    saveData() {
      return this.$store.getters['contracts/dataSaveStatus']
    },
  },
  watch: {
    documentCurrentPage(val) {
      this.documentdataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDocuments()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.getLines()
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
    documentSaveStatus(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.documentModal.loadSpinner = false
        this.documentModal.status = false
        this.getDocuments()
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.getData()
    this.getDocuments()
  },
  methods: {
    getData() {
      this.$store.dispatch('contracts/getDataItem', this.$route.params.id)
        .then(response => {
          if (response.id_customers) {
            this.getCustomer(response.id_customers)
          }
        })
    },
    getDocuments() {
      this.$store.dispatch('contractDocuments/getDataList', this.documentdataQuery)
    },
    getCustomer(id) {
      this.$store.dispatch('customers/getDataItem', id)
    },

    openDocumentModal() {
      this.documentModal.title = null
      this.documentModal.filename = null
      this.documentModal.status = true
      this.documentModal.loadSpinner = false
    },

    uploadDocument() {
      if (this.documentModal.status) {
        this.documentModal.loadSpinner = true
        this.$store.dispatch('contractDocuments/saveData', {
          id_contracts: this.$route.params.id,
          title: this.documentModal.title,
          filename: this.documentModal.filename,
        })
      }
    },
    showDocument(url) {
      window.open(url, '_blank')
    },
    removeDocument(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('contractDocuments/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    download(lang) {
      window.open(`${this.$store.state.app.baseURL}/Exports/contracts/pdf?token=${localStorage.getItem('downloadToken')}&id=${this.$route.params.id}&lang=${lang}`, '_blank')
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
